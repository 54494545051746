import $ from 'jquery'
import './main.scss'

$(document).on('click', '[rel=share]', function (e) {
  e.preventDefault()
  return window.open(
    this.href,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
  )
})
